import { faAngleRight as lightAngleRight } from "@jewlr/pro-light-svg-icons/faAngleRight"
import { faTimes as lightTimes } from "@jewlr/pro-light-svg-icons/faTimes"
import { Box, Flex, FontAwesomeIcon, Image } from "@jewlr/storybook/core"
import { Confirmation } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import styled from "styled-components"

import { removeFromCart } from "app-store-v2/header/actions"
import { jewlr_t } from "helpers/application"

import {
  ItemAction,
  CardItemInfo,
  CardTitle,
  Card,
  CardRemove,
} from "../components"

import LineAddonPrice from "./line-addon-price"

const ItemActionWrap = styled(Box).attrs((props) => ({
  color: props.theme.colors.link,
  fontSize: { tablet: 14 },
  mb: 5,
}))`
  :hover {
    color: ${(props) => props.theme.colors.link};
  }
`

const BoxImage = styled(Image)`
  background-color: ${(props) => props.theme.colors.black};
  margin: 0 0 0 0;
  padding: 8px;
`

const InternalLineItemAddon = ({
  addToBagExp,
  item,
  removeAddonItem,
  addon,
}) => {
  if (!addon) return false
  const [showRemoveDialog, toggleRemoveDialog] = useState(false)

  return (
    <Card mt={3} pt={3}>
      <CardRemove
        data-cy={`cart-remove-btn-mobile-${item.style_code}`}
        onClick={() => toggleRemoveDialog(true)}
      >
        <FontAwesomeIcon icon={lightTimes} />
      </CardRemove>
      <Confirmation
        cancelText="Cancel"
        confirmText="Yes"
        description={`Are you sure you want to remove this item from your ${jewlr_t(
          "Cart",
          addToBagExp
        )}?`}
        onCancel={() => toggleRemoveDialog(false)}
        onConfirm={() => {
          removeAddonItem(addon.id)
          toggleRemoveDialog(false)
        }}
        show={showRemoveDialog}
        title="Remove Item"
        variant="squircle"
      />

      <CardTitle as={Link} data-lc="line-item-addon" to={addon.modify_url}>
        {addon.title}
      </CardTitle>
      <Flex alignItems="flex-end" mt={1}>
        <Box width="50%">
          <Link data-lc="line-item-addon" to={addon.modify_url}>
            {addon.image_url?.startsWith("https://user-uploads.jewlr.com") ? (
              <BoxImage
                alt={addon.title}
                data-cy={`cart-line-item-box-img-mobile-${item.style_code}`}
                height="145px"
                src={addon.image_url}
                width="145px"
              />
            ) : (
              <Image
                alt={addon.title}
                data-cy={`cart-line-item-box-img-mobile-${item.style_code}`}
                height="145px"
                src={addon.image_url}
                width="145px"
              />
            )}
          </Link>
        </Box>
        <CardItemInfo m="0" textAlign="right" width="50%">
          <LineAddonPrice
            displayPrice={addon.price}
            retailPrice={addon.retail_price}
            showSavings={addon.price != addon.retail_price}
          />
          <ItemActionWrap>
            <ItemAction
              as={Link}
              data-lc="line-item-addon"
              to={`${addon.modify_url}&panel=box`}
            >
              Modify Item{" "}
              <FontAwesomeIcon
                icon={lightAngleRight}
                style={{ verticalAlign: "text-bottom" }}
              />
            </ItemAction>
          </ItemActionWrap>
        </CardItemInfo>
      </Flex>
    </Card>
  )
}

InternalLineItemAddon.propTypes = {
  addToBagExp: PropTypes.number,
  addon: PropTypes.object,
  item: PropTypes.object,
  removeAddonItem: PropTypes.func,
  toggleInstantGiftPreview: PropTypes.func,
}

const mapStateToProps = (state) => {
  return { addToBagExp: state.experiment.addToBag.variant_id }
}

const mapDispatchToProps = (dispatch) => {
  return {
    removeAddonItem: (addonId) => {
      dispatch(removeFromCart(addonId, true))
    },
  }
}

const LineItemAddon = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalLineItemAddon)

export default LineItemAddon
