import { faChevronDown } from "@jewlr/pro-regular-svg-icons/faChevronDown"
import { faChevronUp } from "@jewlr/pro-regular-svg-icons/faChevronUp"
import { Expand } from "@jewlr/storybook/animate"
import {
  Box,
  Flex,
  Image,
  Typography,
  FontAwesomeIcon,
} from "@jewlr/storybook/core"
import { Confirmation } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"

import {
  DetailBtn,
  ItemAction,
  ItemTitle,
  ShadowBox,
  DetailText,
  RetailPrice,
} from "areas/cart/components"
import { removeAddon } from "areas/cart/store/actions"
import { jewlr_t } from "helpers/application"
import { to_currency } from "helpers/number"

const InternalAddonDesktopItem = ({
  addon,
  addToBagExp,
  index,
  deleteExclusiveAddon,
}) => {
  const [showAddonDetail, toggleAddonDetail] = useState(false)
  const [showRemoveItemDialog, toggleRemoveItemDialog] = useState(false)
  return (
    <ShadowBox
      data-cy={`cart-addon-desktop-${index}-${addon.addon_code}`}
      id={"addon_item_" + addon.id}
      key={`d_addon-${addon.id}`}
    >
      <Flex data-cy="cart-addons" gap="10px" mx={0}>
        <Image
          alt={addon.title}
          height="128px"
          src={addon.image_url}
          title={addon.title}
          width="128px"
        />
        <Box flex="1">
          <ItemTitle mb="12px">{addon.title}</ItemTitle>
          <ItemAction
            data-cy={`cart-addon-remove-btn-desktop-${index}-${addon.addon_code}`}
            onClick={() => toggleRemoveItemDialog(true)}
          >
            Remove
          </ItemAction>
          <DetailBtn
            aria-controls={`cart-addon-details-desktop-${addon.id}`}
            aria-expanded={showAddonDetail}
            onClick={() => {
              toggleAddonDetail(!showAddonDetail)
            }}
          >
            View Item Details{" "}
            <FontAwesomeIcon
              icon={showAddonDetail ? faChevronUp : faChevronDown}
              style={{ verticalAlign: "text-bottom" }}
              width="8px"
            />
          </DetailBtn>
          <Expand
            id={`cart-addon-details-desktop-${addon.id}`}
            show={showAddonDetail}
          >
            <DetailText>{addon.description}</DetailText>
          </Expand>
        </Box>
        <Box textAlign="right">
          <Typography
            color={addon.price == 0.0 ? "greenDarker" : "sale"}
            fontSize={32}
            fontWeight="300"
          >
            {addon.price == 0.0 ? "FREE" : to_currency(addon.price)}
          </Typography>
          <RetailPrice>
            RETAIL <strike>{to_currency(addon.retail_price)}</strike>
          </RetailPrice>
        </Box>
      </Flex>
      <Confirmation
        cancelText="Cancel"
        confirmText="Yes"
        modalType="popup"
        onCancel={() => toggleRemoveItemDialog(false)}
        onConfirm={() => {
          deleteExclusiveAddon(addon.id)
          toggleRemoveItemDialog(false)
        }}
        render={() => (
          <Typography px={3}>
            Are you sure you want to remove this item from your{" "}
            {jewlr_t("Cart", addToBagExp)}?
          </Typography>
        )}
        show={showRemoveItemDialog}
        title="Remove Item"
        variant="squircle"
      />
    </ShadowBox>
  )
}

InternalAddonDesktopItem.propTypes = {
  addToBagExp: PropTypes.number,
  addon: PropTypes.object,
  deleteExclusiveAddon: PropTypes.func,
  exclusiveAddonsRef: PropTypes.func,
  index: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {
    addToBagExp: state.experiment.addToBag.variant_id,
    addons: state.cart.addons.filter(
      (a) => a.addon_type === "BUNDLE" || a.addon_type === "GIFT_CARD"
    ),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteExclusiveAddon: (addon_id) => {
      dispatch(removeAddon(addon_id))
    },
  }
}

const AddonDesktopItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalAddonDesktopItem)

export default AddonDesktopItem
