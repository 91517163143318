import { Box, Flex, Typography, Image } from "@jewlr/storybook/core"
import { Confirmation } from "@jewlr/storybook/modal"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import styled from "styled-components"

import { ItemTitle, ItemAction, RetailPrice } from "areas/cart/components"
import { jewlr_t } from "helpers/application"
import { to_currency } from "helpers/number"

const BoxImage = styled(Image)`
  background-color: ${(props) => props.theme.colors.black};
  display: block;
  margin: 0 auto;
  padding: 8px;
`
const BoxPrice = styled(Typography).attrs((props) => ({
  as: "h3",
  fontSize: 34,
  fontWeight: 300,
  ...props,
}))``

const InfoBox = styled(Box).attrs((props) => ({
  ...props.theme.cart.lineItemInfo,
}))``

const Addon = ({ addToBagExp, boxAddon, item, removeAddonItem }) => {
  const [showRemoveDialog, toggleRemoveDialog] = useState(false)
  return (
    <Flex gap="10px" pt="10px">
      {boxAddon && (
        <>
          {boxAddon.image_url?.startsWith("https://user-uploads.jewlr.com") ? (
            <BoxImage
              alt={boxAddon.title}
              data-cy={`cart-line-item-box-img-${item.style_code}`}
              height={128}
              src={boxAddon.image_url}
              title={boxAddon.title}
              width={128}
            />
          ) : (
            <Image
              alt={boxAddon.title}
              height={128}
              src={boxAddon.image_url}
              width={128}
            />
          )}
        </>
      )}
      {boxAddon && (
        <>
          <InfoBox flex={1}>
            <ItemTitle mb={2}>{boxAddon.title}</ItemTitle>
            <ItemAction onClick={() => toggleRemoveDialog(true)}>
              Remove
            </ItemAction>
          </InfoBox>
          <Box textAlign="right">
            <BoxPrice
              color={boxAddon.retail_price == boxAddon.price ? "black" : "sale"}
              fontSize={34}
            >
              {to_currency(boxAddon.price)}
            </BoxPrice>
            {boxAddon.retail_price != boxAddon.price && (
              <RetailPrice>
                RETAIL {to_currency(boxAddon.retail_price)}
              </RetailPrice>
            )}
          </Box>
        </>
      )}
      <Confirmation
        cancelText="Cancel"
        confirmText="Yes"
        modalType="popup"
        onCancel={() => toggleRemoveDialog(false)}
        onConfirm={() => {
          removeAddonItem(boxAddon.id)
          toggleRemoveDialog(false)
        }}
        render={() => (
          <>
            Are you sure you want to remove this item <br />
            from your {jewlr_t("Cart", addToBagExp)}?
          </>
        )}
        show={showRemoveDialog}
        title="Remove Item"
        variant="squircle"
      />
    </Flex>
  )
}

Addon.propTypes = {
  addToBagExp: PropTypes.number,
  boxAddon: PropTypes.object,
  item: PropTypes.object,
  removeAddonItem: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    addToBagExp: state.experiment.addToBag.variant_id,
  }
}

export default connect(mapStateToProps)(Addon)
